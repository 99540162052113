<template>
  <div>
    <SingUpUserForm />
  </div>
</template>

<script>
import SingUpUserForm from '@/components/SingUpUserForm.vue';

export default {
  components: { SingUpUserForm },
};
</script>
